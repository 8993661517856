:root {
  --font-telegraf: "telegraf", "helvetica";
  --font-dm-sans: "dm sans", "arial";

  --color-white: 255, 255, 255; /* #ffffff */
  --color-gray50: 246, 246, 246; /* #f6f6f6 */
  --color-gray100: 237, 237, 237; /* #ededed */
  --color-gray200: 224, 224, 224; /* #e0e0e0 */
  --color-gray300: 202, 202, 202; /* #CACACA */
  --color-gray400: 134, 134, 134; /* #868686 */
  --color-black: 25, 25, 25; /* #191919 */

  --color-goldenrod: 242, 255, 208; /* #F2FFD0 */
  --color-ultramoss: 196, 238, 77; /* #c4ee4d */
  --color-overgrown: 33, 110, 5; /* #216e05 */
  --color-forest: 25, 76, 7; /* #194c07 */
  --color-lindworm: 16, 42, 8; /* #102a08 */

  --color-chalk: 239, 231, 255; /* #efe7ff */
  --color-lavender: 201, 176, 255; /* #c9b0ff */
  --color-orchid: 120, 105, 253; /* #7869fd */
  --color-azul: 52, 78, 216; /* #344ed8 */
  --color-depths: 14, 8, 70; /* #0e0846 */

  --color-breeze: 226, 249, 250; /* #E2F9FA */
  --color-pond: 139, 231, 236; /* #8be7ec */
  --color-sea: 57, 199, 207; /* #39c7cf */
  --color-meadow: 132, 234, 197; /* #84eac5 */
  --color-glaze: 4, 52, 55; /* #043437 */

  --color-green: 43, 178, 21; /* #2bb215 */
  --color-green-light: 238, 249, 237; /* #eef9ed */
  --color-red: 255, 0, 61; /* #ff003d */
  --color-red-medium: 250, 206, 217; /* #faced9 */
  --color-red-light: 255, 236, 239; /* #ffecef */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;

}
 
html::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

html::-webkit-scrollbar-track {
  background: rgb(var(--color-gray200));
  border-radius: 4px;
}

html::-webkit-scrollbar-thumb {
  background: rgb(var(--color-gray400));
  border-radius: 4px;
}

html::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--color-gray300));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


sub,
sup {
  font-size: 0.625em;
  vertical-align: super;
  line-height: 0;
}
sub {
  vertical-align: sub;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg,
video,
canvas {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}

body,
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: telegraf;
  src: url(../../assets/fonts/telegraf-400.otf) format("opentype");
  font-display: swap;
  font-weight: 400;
  ascent-override: 94%;
  descent-override: 24%;

}

@font-face {
  font-family: telegraf;
  src: url(../../assets/fonts/telegraf-500.otf) format("opentype");
  font-display: swap;
  font-weight: 500;
  ascent-override: 94%;
  descent-override: 24%;
}

@font-face {
  font-family: telegraf;
  src: url(../../assets/fonts/telegraf-600.otf) format("opentype");
  font-display: swap;
  font-weight: 600;
  ascent-override: 94%;
  descent-override: 24%;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:wght@500;600&family=Open+Sans:wght@400;700&display=swap')
