.custom-dot-list-style { 
    position: absolute;
    bottom:0;
    left: 0;
    justify-content: center;
    padding: 0px 0px;
}

.react-multi-carousel-dot {
    .button{
        border-radius: 0;
    }
}
.carousel-container {
    padding-bottom: 20px;
}